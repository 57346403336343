.loading {
    margin: 0 auto;
    position: absolute;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

.marker {
    background-image: url('https://w7.pngwing.com/pngs/331/692/png-transparent-ping-computer-icons-others-computer-network-cdr-internet.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}
.mapboxgl-popup {
    max-width: 200px;
}
.mapboxgl-popup-content {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.custom-scrollbar {
    height: 200px;
    overflow: auto;
    scrollbar-width: thin; /* Adiciona uma largura de scrollbar mais fina */
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px; /* Largura da barra de rolagem */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: #0d1727; /* Cor do fundo da barra de rolagem */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #1765e7; /* Cor do "polegar" da barra de rolagem */
    border-radius: 4px; /* Arredonda as bordas do "polegar" */
}

.floating-div {
    position: fixed;
    bottom: 10px;
    right: 10px;
    overflow: auto;
    padding: 10px;
    z-index: 1000;
}

.alert-hidden{
    display: none;
}