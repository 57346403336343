.eva {
    position: fixed;
    z-index: 999;
    bottom: 0px;
    right: -10px;
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 1050px) {
    .eva {
        display: none;
    }
}

@media screen and (max-height: 700px) {
    .evinha {
        max-height: 600px !important;
    }
}
