.adicionais__cardOcorrencias {
    height: 30%;
}

.adicionais__info{
    padding: 0 0.5em 0 0 !important;
}
.adicionais__grupoCards {
    padding: 0 0 0 1em !important;
}
.adicionais__peso {
    padding: 0 0 0 1em !important;
}