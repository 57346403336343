.carregamentos{
    padding: 1em 1em 0 1em;
}
.carregamentos__grupo {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0
}
.carregamentos__item {
    display: flex;
}
.carregamentosIndicador {
    padding-top: 1em;
}
.carregamentosIndicador__grafico {
    padding: 0 1em 1em 1em !important;
}
.carregamentosIndicador__onTime {
    padding: 0 1em 1em 0 !important;
}
.carregamentosIndicador__onTimeSmall {
    display: none;
}
.carregamentosCards {
    display: flex;
    justify-content: space-between;
}
.carregamentosCards__child {
    display: flex;
    padding: 0 0.5em !important;
    min-width: 200px;
    max-width: 300px;
}

.carregamentosCards__child:nth-child(5n) {
    padding-right: 1em !important;
}
.carregamentosCards__child:nth-child(5n+1) {
    padding-left: 1em !important;
}

@media screen and (max-width: 992px) {
    .carregamentosIndicador__onTime {
        padding: 0 1em 0 0 !important;
    }
    .carregamentosCards__child {
        padding: 0 1em 1em 1em !important;
    }
}

@media screen and (max-width: 600px) {
    .carregamentosIndicador__onTime {
        display: none;
    }
    .carregamentosIndicador__onTimeSmall {
        display: block;
        padding: 1em !important;
    }
    .carregamento__grupoSmall {
        display: none;
    }
}
.custom-input::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  .custom-input::-moz-calendar-picker-indicator {
    filter: invert(1);
  }
