.descargas{
    padding: 1em 1em 0 1em;
}
.descargas__grupo {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0
}
.descargas__item {
    display: flex;
}
.descargaCards {
    display: flex;
    justify-content: space-between;
    padding-top: 1em;
}
.descargaCards__child {
    display: flex;
    padding: 0 0.5em 1em 0.5em !important;
}
.descargaCards__child:nth-child(3n+1) {
    padding-left: 1em !important;
}
.descargaCards__child:nth-child(3n) {
    padding-right: 1em !important;
}
.descargaIndicadores__badActor {
    padding: 0 0.5em 0 1em !important;
}
.descargaIndicadores__onTime {
    padding: 0 1em 0 0.5em !important;
}
.descargaIndicadores__onTimeSmal,
.descargaIndicadores__pesoSmal {
    display: none;
}

.desargaIndicador__verMais {
    min-height: 113px;
}

@media screen and (max-width: 992px) {
    .descargaCards__child {
        padding: 0 1em 1em 0 !important;
    }
    .desargaIndicador__verMais {
        min-height: 98px;
    }
    .descargaIndicadores__onTime {
        display: none;
    }
    .descargaIndicadores__onTimeSmal {
        display: block;
        padding-bottom: 1em;
    }
    .descargaIndicadores__badActorSmall {
        margin-top: 2em;
    }
}

@media screen and (max-width: 600px) {
    .descargaCards__child {
        padding: 0 1em 1em 1em !important;
    }
    .descargaIndicadores__badActor {
        display: none;
    }
    .descargaIndicadores__pesoSmal {
        display: block;
        margin-top: 1em;
    }
}