body {
    overflow: "hidden";
}

html {
    overflow: "hidden";
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.8);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    bottom: 10px;
    left: 0;
    margin: 12px;
    border-radius: 4px;
}

.footerbar {
    background-color: rgba(35, 55, 75, 0.8);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 10px;
    left: 0;
    margin: 12px;
    border-radius: 4px;
    min-width: 800px;
}

.viagensbar {
    background-color: rgba(35, 55, 75, 0.8);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 40px;
    margin: 12px;
    border-radius: 4px;
    min-width: 400px;
    width: 30%;
}

.loading {
    margin: 0 auto;
    position: absolute;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

.marker {
    background-image: url('https://w7.pngwing.com/pngs/331/692/png-transparent-ping-computer-icons-others-computer-network-cdr-internet.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}
.mapboxgl-popup {
    max-width: 200px;
}
.mapboxgl-popup-content {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}