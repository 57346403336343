.loader {
    display: flex;
}

.loader__element:nth-child(1) {
    animation: preloader .6s ease-in-out alternate infinite;
}
.loader__element:nth-child(2) {
    animation: preloader .6s ease-in-out alternate .2s infinite;
}

.loader__element:nth-child(3) {
    animation: preloader .6s ease-in-out alternate .4s infinite;
}

@keyframes preloader {
    100% { transform: scale(1.5); }
}

@media screen and (max-width: 600px) {
    .loader{
        justify-content: center;
    }
}